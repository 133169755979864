<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'ApplianceModel', title: 'Моделі приладів' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_appliance_model)"
        :modal="show_appliance_model_dialog"
        @updateItemModal="applianceModelUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Список моделей приладів
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openApplianceModelCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити нову модель приладу</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-1"
              @click:row="onApplianceModelItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon>
              <v-icon size="26" color="success" class="mr-2">
                mdi-format-list-numbered
              </v-icon>
            </template>

            <template v-slot:item.appliance_type="{ item }">
              <span>
                {{ getApplianceType(item.appliance_type) }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_APPLIANCE_MODEL} from "@/store/actions/appliance";
import {getApplianceType} from "@/utils/icons"

export default {
  props: ['selectable', 'model', 'appliance_type'],
  name: "ApplianceModelView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getApplianceModels'
    })
  },
  data() {
    return {
      selected: [],
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Найменування', value: 'short_name' },
        { text: 'Найменування (повне)', value: 'full_name' },
        { text: 'Тип приладу', value: 'appliance_type' },
      ],
      selected_appliance_model: {},
      show_appliance_model_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_ALL_APPLIANCE_MODEL
    }),
    getApplianceType,
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    applianceModelUpdateItemModal() {
      this.show_appliance_model_dialog = false
      this.selected_appliance_model = {}
    },
    openApplianceModelCreateDialog() {
      this.selected_appliance_model = {appliance_type: this.appliance_type}
      this.show_appliance_model_dialog = true
    },
    onApplianceModelItemClick(payload) {
      this.selected_appliance_model = JSON.parse(JSON.stringify(payload))
      this.show_appliance_model_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch(this.appliance_type)
      }
    }
  },
  created() {
    this.fetch(this.appliance_type)
  }
}
</script>

<style scoped>

</style>